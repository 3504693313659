import { vuexfireMutations, firestoreAction } from 'vuexfire'
import { firestore } from '@/plugins/firestore'

const MESSAGE_COLLECTION_NAME =
  process.env.ctiEnv === 'production'
    ? 'messages_chitose'
    : 'messages_chitose_dev'
const messageRef = firestore.collection(MESSAGE_COLLECTION_NAME)

export const state = () => ({
  messages: [],
  lastReadMessageDate: Date.now()
})

export const mutations = {
  // Vuexfireのミューテーションを追加して bindmethodを呼出し
  ...vuexfireMutations,

  setMessages(state, messages) {
    state.messages = messages
  },
  setLastReadMessageDate(state, lastReadMessageDate) {
    state.lastReadMessageDate = lastReadMessageDate
  }
}

export const getters = {
  // firebaseのmessageはバインドして、件数を計算
  recentMessageCount: (state) => {
    return state.messages.filter((msg) => {
      return msg.created_at.toDate() > state.lastReadMessageDate
    }).length
  }
}

export const actions = {
  bindMessages: firestoreAction(({ bindFirestoreRef, rootState }) => {
    if (!rootState.auth.user) {
      return Promise.reject(new Error('Unauthorized'))
    }
    // firebaseからmessageを取得、バインドする
    const query = messageRef
      .where('to', 'array-contains-any', [
        'user_all',
        'user' + rootState.auth.user.id
      ])
      .orderBy('created_at', 'desc')
    return bindFirestoreRef('messages', query)
  })
}
